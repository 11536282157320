import React from 'react'
import { Badge, Card, CardBody, CardHeader, Row, Table} from 'reactstrap'

function ServiciosComponent(props) {
    const {ArrayObjs, Titulo}=props;

    const renderizarValor=(unValor)=>{
        if(unValor!==null || unValor!==undefined)
        {
          return unValor;
        }
        else{
          let valor='No definido';
          return valor;
        }
    }
    const EstadoServicio =(serv)=>{
        if(serv.suspensionReasonId == null){
            return true;
          }
          else{
            return false;
          }

    }

    return (
        <>
                {ArrayObjs?

                    ArrayObjs.map((planCt, index) => {
                        return (
                                <Card className="text-center ml-4 mr-4" key={index}>
                                    <CardHeader>
                                        <h2 className='float-left'>{Titulo}</h2>
                                    </CardHeader>
                                    <CardBody>
                                    <Row>
                                    
                                    <Table className="align-items-center" responsive>
                                        <thead className="thead-light text-center">
                                            <tr>
                                            <th scope="col">PLAN CONTRATADO</th>
                                            <th scope="col">ESTADO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <th scope="row">
                                                <div className="text-center">
                                                    <span className="mb-0 text-sm">
                                                    {renderizarValor(planCt?.servicePlanName)}
                                                    </span>
                                                </div>
                                                </th>
                                                <td>
                                                <div className="text-center">
                                                    <span className="mb-0 text-sm">
                                                    {
                                                        EstadoServicio(planCt)?
                                                        <Badge className="badge-lg" color="success">Servicio Activo</Badge>
                                                        :
                                                        <Badge className="badge-lg" color="danger">Servicio Suspendido</Badge>
                                                    }
                                                    </span>
                                                </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        </Table>
                                        </Row>
                                    </CardBody>
                                </Card>
                        )
                    }
                    )
                    :
                    <>
                    </>
                
                }
        </>
    );
}

export default ServiciosComponent;