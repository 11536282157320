import React, { useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Container, Form, Input } from 'reactstrap'
import { validarClienteDNI } from 'services/AuthService';
import Swal from 'sweetalert2';

const CardDni =(props)=> {
    const {setDni, setEstaValidado}= props;
    const [UnDni, setInputDNI] = useState('');


    const setearDni=(e)=>{
		setInputDNI(e.target.value);
	}

    const alertaErrorValidar=(usuario)=>{
        Swal.fire(
            'Error!',
            `Al parecer el DNI: ${usuario.dni} no existe en nuestros registros`,
            'error'
          );
    }
    const validarDNI=(e)=>{
        let usuario={
          dni:UnDni
        }
        console.log("usuario", usuario)
        //lamo a la api
        validarClienteDNI(usuario)
        .then(data => {
          //console.log("ESTOY LOGUEADO",data);
          if(data.ok===true)
          {
            setDni(UnDni);
            setEstaValidado();
           
          }
          else
          {
            alertaErrorValidar(usuario);
          }
        })
        .catch(err=>{
          console.log(err);
        });
      }
  return (
      <>
      <Container className='mt-5'>
          <Card className='mt-5' style={{width:"100%"}}>
              <CardHeader className='text-center'><h2 style={{fontSize:"1.5rem"}}>Paso 1- Identificación</h2></CardHeader>
              <CardBody className='text-center'>
                
                  <h3 style={{fontSize:"1.2rem"}}>Información del Usuario</h3>
                  <Form>
                      <label style={{fontSize:"1rem"}}> DNI del titular (Sin Puntos)</label>
                      <Input
                      className='mt-3'
                      type='number'
                      placeholder='DNI sin puntos...'
                      value={UnDni} 
                      onChange={setearDni}/>
                  </Form>
              <CardFooter>
                  <Button className='btn-md btn-default mt-3' onClick={()=>validarDNI()}>Validar mi DNI</Button>
              </CardFooter>
              </CardBody>
          </Card>
      </Container>
      </>
  )
}

export default CardDni
