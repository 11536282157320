import { urlGlobal2 } from "./GlobalService";

export async function postLogOut(){
    try {
      let Url = new URL(`${urlGlobal2}/auth/logout`)
      let token = localStorage.getItem('token');
      let method = 'POST';
      let headers = {'Content-Type':'application/json','token':token};
      const resp = await fetch(Url, {method,headers});
      const data = await resp.json();
      localStorage.removeItem('token');
      return data;    
    } catch (error) {
      alert(error)
      localStorage.removeItem('token');
      return null
    }
  }
  export const validarClienteDNI = async(usuario)=>{
    let body = JSON.stringify(usuario);
    let headers = {'Content-Type':'application/json'};
     const resp = await fetch(`${urlGlobal2}/auth/generoUsuario`,{headers,body,method:'POST'});
    
     const data = await resp.json();
   
     return data;
}
export const registrarCliente = async(usuario)=>{
  let body = JSON.stringify(usuario);
  let headers = {'Content-Type':'application/json'};
   const resp = await fetch(`${urlGlobal2}/auth/generarUsuarioCliente`,{headers,body,method:'POST'});
  
   const data = await resp.json();
 
   return data;
}
