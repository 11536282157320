import moment from 'moment';
import LoadingComponent from 'pages/components/Loading';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Badge, Button, Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap'
import { getFacturas } from 'services/ClienteService';
import { descargarFactura } from 'services/FacturasService';

const Facturas = props => {
 const [Facturas, setFacturas]= useState([]);
 const [Visible, setVisible] = useState(false);
 const history = useHistory();

 const desloguear = () => {
  localStorage.removeItem('token');
   history.replace('/auth/login');
}

const ordenar =(a,b)=>{
  return new Date(b.createdDate) - new Date(a.createdDate);
}

 const traerFaturas = async()=>{
    setVisible(true)
   let facturas = await getFacturas();
   facturas.sort(ordenar)
   if (facturas !==null && facturas !=="") {
       setFacturas(facturas);
   }
   setVisible(false)
 }

const verificarDeudaParticular=(noPagado,pagado)=>{
    if(noPagado > pagado)
    {
      return false
    }
    else
    {
      return true
    }
}
const guardarPdf =(idFactura) => {
   descargarFactura(idFactura)
}





 useEffect(() => {
    traerFaturas();
    }, [])   
  return (
      <>
      <LoadingComponent Visible={Visible}/>
      <Card className='mt-4 ml-4 mr-4'>
          <CardHeader>
            <Row>
                <Col>
                <h2>MIS FACTURAS</h2>
                </Col>
            </Row>
          </CardHeader>
          <CardBody>
              <h2>Historial de Facturas</h2>
            <Table className="align-items-center" responsive>
                <thead className="thead-light text-center">
                    <tr>
                    <th scope="col">NUMERO</th>
                    <th scope="col">PERIODO</th>
                    <th scope="col">FECHA DE VENCIMIENTO</th>
                    <th scope="col">TOTAL DEL MES</th>
                    <th scope="col">TOTAL PAGADO</th>
                    <th scope="col">ESTADO</th>
                    <th scope="col">DESCARGAR</th>
                    </tr>
                </thead>
                <tbody>
                {Facturas?
                Facturas.map((f, index)=>{
                let total=verificarDeudaParticular(f.amountToPay,f.amountPaid);
                    return(
                    <tr key={index}>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {f.number}
                          </span>
                    </div>
                    </td>
                    <td scope="row">
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {moment(f.createdDate).format('DD/MM/YYYY')}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {moment(f.dueDate).format('DD/MM/YYYY')}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            $ {f.amountToPay}
                          </span>
                    </div>
                    </td>
                    <th scope="row">
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            $ {f.amountPaid}
                          </span>
                    </div>
                    </th>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {
                                total?
                                <Badge className="badge-lg" color="success">PAGADO</Badge>
                                :
                                <Badge className="badge-lg" color="danger">ADEUDA</Badge>
                            }
                          </span>
                    </div>
                    </td>
                    <td>                 
                    <div className="text-center">
                          <Button  
                          className=" btn-sm btn-outline-default" 
                          type="button" 
                          onClick={()=>{guardarPdf(f.id)}}
                          >
                              <span className="btn-inner--icon">
                              <i className='fas fa-download'></i>
                              </span>
                          </Button>
                    </div>
                    </td>
                    </tr>
                    )
                })
                    :
                    <>Cargando...</>}
                </tbody>
            </Table>
          </CardBody>
      </Card>
      </>
  )
}

export default Facturas
