import { urlGlobal2 } from "./GlobalService";


export const loguearCliente = async(usuario)=>{
    let body = JSON.stringify(usuario);
    let headers = {'Content-Type':'application/json'};
     const resp = await fetch(`${urlGlobal2}/auth/loginClientes`,{headers,body,method:'POST'});
    
     const data = await resp.json();
   
     return data;
}


export const desloguearCliente = async(usuario)=>{
    let body = JSON.stringify(usuario);
    let headers = {'Content-Type':'application/json'};
     const resp = await fetch(`${urlGlobal2}/auth/logout`,{headers,body,method:'POST'});
    
     const data = await resp.json();
   
     return data;
}

// export const generateToken = async(d) => {
//     let body = JSON.stringify(d);
//     let headers = {'Content-Type':'application/json','apiKey':publicKey};
//     const resp = await fetch(`${urlDecidir}/tokens`,{headers,body,method:'POST'});
    
//     const data = await resp.json();
  
//     return data;
// }

export const getLinkPagoServ = async(serviceId) => {
    const nResp = await fetch(`${urlGlobal2}/servicios/generarLinkEfectivo/${serviceId}`);
    const nData = await nResp.json();

    return nData;
}

//dni y password




