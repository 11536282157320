import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { Button, Card, CardBody, CardFooter, CardHeader, Container, Form, Input } from 'reactstrap'

const CardPassword = props => {
    const {setPassword}= props;

  const [unaPassword, setInputPassword] = useState(''); //hay que poner un '' sino tira error
  const [unaPassword2, setInputPassword2] = useState('');


 
  const setearPassword=(e)=>{
        setInputPassword(e.target.value);
  }
  const setearPassword2=(e)=>{
		setInputPassword2(e.target.value);
  }

  const verClave=(e)=>{
    let showPassword = document.querySelector('.show-password');
    let tipo = document.getElementById("passwordFinal");
      if(tipo.type == "password"){
          tipo.type = "text";
          showPassword.classList.toggle("fa-eye-slash");
      }else{
          tipo.type = "password";
          showPassword.classList.remove('fa-eye-slash');
          
      }
  }
  const alertaErrorClaves=()=>{
    Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Verifique las claves'
      });
}

  const verificarDniClave=(e)=>{
      if(unaPassword!=unaPassword2 || unaPassword==""){
        alertaErrorClaves();
      }
      else{
        //esta todo ok
        setPassword(unaPassword);
      }
  }

  return (
    <Container className='mt-5 ' fluid>
          <Card className='mt-5' style={{width:"100%"}}>
              <CardHeader className='text-center'><h2>Paso 2- Datos Finales Del Usuario</h2></CardHeader>
              <CardBody>
                  <Form>
                      <label>Contraseña</label>
                      <Input
                      type='password'
                      placeholder='Contraseña...'
                      value={unaPassword} onChange={setearPassword}
                      id="passwordFinal"/>
                      <span onClick={()=>verClave()} className="mt-2 mb-3 fa fa-fw fa-eye password-icon show-password"></span>
                  </Form>
                  <Form>
                      <label>Reingrese su Contrasña</label>
                      <Input
                      type='password'
                      placeholder='Contraseña...'
                      value={unaPassword2} onChange={setearPassword2}/>
                  </Form>
              <CardFooter className='d-flex justify-content-center'>
                  <Button className='btn-md btn-default' onClick={()=>verificarDniClave()}>Validar mi DNI</Button>
              </CardFooter>
              </CardBody>
          </Card>
      </Container>
  )
}


export default CardPassword
