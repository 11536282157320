import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import './styles/FacturaComponentStyles.css';
import { getDatos } from "services/DatosService";
import { descargarFactura } from "services/FacturasService";
import FacturaComponent from "./FacturaComponent";
import { getFacturas } from "services/ClienteService";


function DescargarFactura(props) {
    const [Factura, setFactura] = useState(null);
    const [Cliente, setCliente] = useState(null);
    const [Cae, setCae] = useState(null)
    const [TipoFactura, setTipoFactura] = useState(null)
    const [FechaFacturacion, setFechaFacturacion] = useState(null)

    const SearchByKey = (attributes,key) => {
      let value = null;
      attributes.forEach(a => {
        if(a.key == key){ value = a.value}
      })

      return value;
    }

    const traerDatosFactura = async() => {
      const facturaId=props.match.params.id;
      console.log("facturaId:\n---------\n",facturaId)
      let fact = await getFacturas(facturaId);
      console.log("Datos Factura:\n------------------\n", fact)
      if (fact !== null && fact !== '') {
        setCae(SearchByKey(fact.attributes,'cae'))
        setTipoFactura(SearchByKey(fact.attributes,'tipofactura'))
        setFechaFacturacion(SearchByKey(fact.attributes,'fechafacturacion'))
        traerDatos()
        setFactura(fact);
      }
     }
     const traerDatos = async() => {
      let datos = await getDatos()
      console.log("cliente:", datos)
      
      if(datos){
        setCliente(datos);
      }
      }
        
    


    useEffect(() => {
      traerDatosFactura();
    },[]);
    const guardarPdf = (idFactura) => {
      descargarFactura(idFactura);
    }
  
    return (
      <>
      {
        Factura && Cliente? 
        <>
          <Container fluid>
            <Row className="mt-5">
              <Col></Col>
              <Col>
                <Card className="text-center">
                      <CardBody>
                          <Button 
                          block 
                          size="lg" 
                          color="primary" 
                          type="button"
                           onClick={()=>guardarPdf(Factura.id)}
                           >Descargar Factura</Button>
                      </CardBody>
                  </Card>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col md="8">
                <Card
                  style={{    
                  paddingTop: 50,
                  paddingBottom:50,
                  paddingLeft: 20,
                  paddingRight: 20,  
                }}
                  className="cardGeneral mt-4" /*id utilizado para cambiar css al imprimir*/
                  >
                      <FacturaComponent
                        Factura={Factura}
                        Cae={Cae}
                        Tipo={TipoFactura}
                        Datos={Cliente}
                        FechaFacturacion={FechaFacturacion}
                      />
                </Card>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </>
        
        :
        <>Cargando...</>
      }
       

      </>
    );
  }

export default DescargarFactura;
