import { urlGlobal2 } from "./GlobalService";


export const getServicios = async() => {
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal2}/public/clientes/servicios`,{headers,method:'GET'});
    const nData = await nResp.json();

    return nData;
}
export const getFacturas = async() => {
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal2}/public/clientes/facturas`,{headers,method:'GET'});
    const nData = await nResp.json();

    return nData;
}
export const getPagos = async() => {
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    const nResp = await fetch(`${urlGlobal2}/public/clientes/pagos`,{headers,method:'GET'});
    const nData = await nResp.json();

    return nData;
}