/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import DatosUsuarios from "pages/private/datosUsuario/DatosUsuarios";
import DescargarFactura from "pages/private/descargarFactura/DescargarFactura";
import Facturas from "pages/private/facturas/Facturas";
import PagosCliente from "pages/private/pagosCliente/PagosCliente";
import Register from "pages/private/register/Register";
import Login from "pages/public/Login";

const routes = [
  // #################################### Private Routes #####################################

  // <<<<<<<<<<<<< Inicio Rutas Sidebar >>>>>>>>>>>>>>

  { visible: true,
    path: "/datosusuario",
    name: "Datos Usuario",
    icon: "fas fa-user text-blue",
    component: DatosUsuarios,
    layout: "/admin",
  },
  { visible: true,
    path: "/facturas",
    name: "Facturas",
    icon: "fas fa-file-invoice-dollar text-orange",
    component: Facturas,
    layout: "/admin",
  },
  { visible: true,
    path: "/pagosCliente",
    name: "Pagos",
    icon: "fas fa-dollar-sign text-green",
    component: PagosCliente,
    layout: "/admin",
  },
  // <<<<<<<<<<<<< Fin Rutas Sidebar >>>>>>>>>>>>>>
  { visible: false,
    path: "/descargarFactura/:id",
    name: "Descargar Factura",
    icon: "fas fa-dollar-sign text-green",
    component: DescargarFactura,
    layout: "/admin",
  },
  
  //Para agregar una nueva ruta en la navbar usar el menu service desde la api2


  
  
  // #################################### Public Routes #####################################

  {
    visible: false,
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
  },
  { visible: false,
    path: "/register",
    name: "Register",
    icon: "fas fa-dollar-sign text-green",
    component: Register,
    layout: "/auth",
  },
];

export default routes;
