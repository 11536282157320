import LoadingComponent from 'pages/components/Loading';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap'
import { getServicios } from 'services/ClienteService';
import { getDatos } from 'services/DatosService';
import ServiciosComponent from './components/ServiciosComponent';

const DatosUsuarios = props => {
    const [Datos, setDatos] = useState([]);
    const [Servicios, setServicios] = useState("");
    const [Visible, setVisible] = useState(false);
    const history = useHistory();

    const traerDatos = async()=>{
      setVisible(true)
      let datos = await getDatos();
      if (datos !==null && datos !=="") {
        setDatos(datos)
      }
      console.log("datos:", datos)
      setVisible(false)
    }
    const desloguear = () => {
      localStorage.removeItem('token');
       history.replace('/auth/login');
    }

    const traerServicios= async()=>{
      let serv = await getServicios();
      if (serv !==null && serv !=="") {
        setServicios(serv)
      }
    }

    useEffect(() => {
      traerDatos();
      traerServicios();
      }, [])   
  return (
    <>
    <LoadingComponent Visible={Visible}/>
    <Card className="ml-4 mr-4 mt-4">
        <CardHeader>
            <Row>
                <Col>
                <h2>Titular</h2>
                </Col>

            </Row>
        </CardHeader>
        <CardBody>
          <Row>
          <h3 className='ml-3'>INFORMACIÓN DEL USUARIO</h3>
        <Table className="align-items-center" responsive>
          <thead className="thead-light text-center">
            <tr>
              <th scope="col">NOMBRE</th>
              <th scope="col">APELLIDO</th>
              <th scope="col">DNI</th>
            </tr>
          </thead>
          <tbody>
                  <tr>
                    <th scope="row">
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {Datos?.firstName}
                          </span>
                    </div>
                    </th>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {Datos?.lastName}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            {Datos?.userIdent}
                          </span>
                    </div>
                    </td>
                  </tr>
          </tbody>
        </Table>
        </Row>
        

        <Row>

        </Row>
        </CardBody>
    </Card>

              <ServiciosComponent ArrayObjs={Servicios} Titulo={"Servicios"} />
    </>
  )
}

export default DatosUsuarios
