import AuthHeader from 'components/Headers/AuthHeader';
import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { Col, Container, Row } from 'reactstrap'
import { registrarCliente } from 'services/AuthService';
import Swal from 'sweetalert2';
import CardDni from './components/CardDni'
import CardPassword from './components/CardPassword';

const Register = props => {
  const history = useHistory();

 
  const [estaValidado, setEstaValido] = useState(false);
  const [usuario,setUsuario] = useState({dni:null,password:null});




  const setDni = (dni) => {
    setUsuario({dni,password:null})
  }

  const setPassword = (password) => {
    console.log(password);
    let usuarioRegister = {
      dni:usuario.dni,
      password:password
    }
    console.log(usuarioRegister);
    registrarse(usuarioRegister);
  }

  //esta funcion la llama CardDni
  const setEstaValidado = () => {
    setEstaValido(true)
  }
    const alertaRegistrarmeExitosa = (usuario) =>{
    Swal.fire(
        'Registrado!',
        `Ud. ha sido registrado exitosamente con el Dni: ${usuario.dni} `,
        'success'
    )
}


  //una vez que valido y seteo la password voy a registrarme
   const registrarse= (usuario)=>{
        Swal.fire({
          title:'Está seguro de registrarse?',
          icon:'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, registrarme!'
      }).then((result) =>{
          if (result.isConfirmed) {
             //LLAMO A LA API 
            registrarCliente(usuario)
            .then(data => {
              //console.log("ESTOY REGISTRADO",data);
              if(data.ok===true)
              {
                alertaRegistrarmeExitosa(usuario);
                //guardo el Token en local storage
                localStorage.setItem('token',data.token);
                
                //una vez que se logueo ok lo llevo a otra pagina
                history.push("/admin/datosUsuario");
              }
              else
              {
                alert(data.message);
              }
            })
            .catch(err=>{
              console.log(err);
            });
          }
      });
 }
  return (
    <>
    <AuthHeader/>
    <Container fluid>
      <Row>
        <Col></Col>
        <Col>
          {!estaValidado?
          <CardDni
          setDni={setDni}
          setEstaValidado={setEstaValidado}/>
          :
          <CardPassword setPassword={setPassword}/>
          }
        </Col>
        <Col></Col>
      </Row>
    </Container>
    </>
    
  )

}

export default Register
