import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import Swal from "sweetalert2";
import { loguearCliente } from "services/LoginService";
import { warningAlert } from "pages/components/SweetAlert";
import loginPc from "../../assets/img/log.png"
import AuthHeader from "components/Headers/AuthHeader";

// -----------------------------------------------------   FIN IMPORTACIONES ----------------------------------------------------

function Login() {
  const history = useHistory();
  //const context = useContext(contextValue);
  
  const [Dni, setDni] = useState('');
  const [Contraseña, setContraseña] = useState(''); //hay que poner un '' sino tira error
  const [AlertS, setAlertS] = useState(null)

  const cambiarContraseña=(e)=>{
		setContraseña(e.target.value);
	}
	const cambiarDni=(e)=>{
		setDni(e.target.value);
	}

  const keyDownFunction =(event) => {
    if (event.keyCode === 13) {
      loguear();
    }
  }
 
  const loguear=async()=>{
		 let usuario={
       dni:Dni,
       password:Contraseña
     }
     let data = await loguearCliente(usuario);
      if(data.ok===true)
      {
        localStorage.setItem('token',data.token);
        history.replace('/admin/datosusuario');
      }
      else
      {
        warningAlert(setAlertS, data.mensaje)
      }
    }
  const olvidoClave=()=>{
    Swal.fire(
      {title: 'Comuníquese con administracion',
      icon: 'info',
      html:
          '<a href="https://www.globalnet.net.ar/">O visite nuestra Web</a> ', 
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText:'Cerrar',
      confirmButtonAriaLabel: 'Cerrar'}
  )
  }
  const irRegistrar=()=>{
    history.replace('/auth/register');
  }

  return (
    <>
    {AlertS}
    <AuthHeader/>
    <Row className="mt-4">
    <Col lg={4}>
    </Col>
    <Col lg={4}>
    <Card>
          <Container>
       
            <CardBody>
            <Form className="mt-3" role="form" onKeyDown={(e)=>keyDownFunction(e)}>
                <FormGroup >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                    placeholder="Ingrese su DNI" 
                    type="number" 
                    value={Dni} 
                    onChange={cambiarDni}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                    placeholder="Ingrese su Contraseña" 
                    type="password" 
                    value={Contraseña} 
                    onChange={cambiarContraseña}
                    />
                  </InputGroup>
                </FormGroup>
            </Form>
            </CardBody>
            <div className="text-center">
            <Row>
              <Col>
                <Button
                  color="primary" size="lg" type="button"
                  onClick={()=>loguear()}
                >
                  Acceder
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                className="my-4 btn-md"
                  color=""
                  type="button"
                  onClick={()=>irRegistrar()}
                >
                  Registrarme
                </Button>                        
              </Col>
              <Col>
                <Button
                className=" my-4 btn-md"
                  color=""
                  type="button"
                  onClick={()=>olvidoClave()}
                >
                  Olvidé mi Clave
                </Button> 
              </Col>
            </Row>
          </div>
          </Container>
          </Card>
    </Col>
    <Col lg={4}>
    </Col>
    </Row>
    
        
    </>
  );
}

export default Login;
