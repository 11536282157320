import axios from "axios";
import {urlGlobal2 } from "./GlobalService";

export const descargarFactura = (id) => { 
    let token = localStorage.getItem('token');
    let headers = {'Content-Type':'application/json','token':token};
    axios(`${urlGlobal2}/public/facturas/imprimir/${id}`, {
        method: 'GET',
        responseType: 'blob', //Force to receive data in a Blob Format
        headers
     })
     .then(response => {
     //Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
     //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
     //Open the URL on new Window
        window.open(fileURL);
     })
     .catch(error => {
        console.log(error);
     });
}

