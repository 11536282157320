import moment from 'moment';
import LoadingComponent from 'pages/components/Loading';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap'
import { getPagos } from 'services/ClienteService';

const PagosCliente = props => {
    const [Pagos, setPagos]= useState([]);
    const [Visible, setVisible] = useState(false);
    const history = useHistory();

    const ordenar =(a,b)=>{
        return new Date(b.createdDate) - new Date(a.createdDate);
    }

    const traerPagos = async()=>{
        setVisible(true)
        let pagos = await getPagos();
        pagos.sort(ordenar)
        if (pagos !==null && pagos !=="") {
            setPagos(pagos)
        }
        setVisible(false)
    }

    const desloguear = () => {
        localStorage.removeItem('token');
         history.replace('/auth/login');
    }
    useEffect(() => {
        traerPagos();
    }, [])  
  return (
      <>
      <LoadingComponent Visible={Visible}/>
      <Card className='mt-4 ml-4 mr-4'>
          <CardHeader>
              <Row>
                  <Col>
                  <h2>MIS PAGOS</h2>
                  </Col>
                  <Col>
                    <Row className='float-right'>
                        <a href='https://pagos.globalnet.net.ar'> 
                            <Button className='btn-md btn-default float-right mr-2'>Pagar</Button>
                        </a>                  
                    </Row>
                  </Col>
              </Row>
              <Row >
                <h4 className='bg-success ml-3 mt-3'>Recuerda que los pagos realizados por Rapipago , MercadoPago o Pago Fácil pueden demorar de 48 a 72hs en acreditarse.</h4>
                  
              </Row>
          </CardHeader>
          <CardBody>
          <h2>Historial de Pagos</h2>
          <Table className="align-items-center" responsive>
                <thead className="thead-light text-center">
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">FECHA</th>
                    <th scope="col">MONTO</th>
                    </tr>
                </thead>
                <tbody>
                {Pagos?
                Pagos.map((p, index)=>{
                    return(

                    <tr key={index}>
                    <th scope="row">
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {p.id}
                          </span>
                    </div>
                    </th>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                          {moment(p.createdDate).format('DD/MM/YYYY')}
                          </span>
                    </div>
                    </td>
                    <td>
                    <div className="text-center">
                          <span className="mb-0 text-sm">
                            $ {p.amount}
                          </span>
                    </div>
                    </td>
                    </tr>
                    )
                })
                    :
                    <>Cargando...</>}
                </tbody>
            </Table>
          </CardBody>
      </Card>
      </>
  )
}

export default PagosCliente
